import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import { DocumentTabPanel } from "../../../../components/DocumentTabPanel/DocumentTabPanel";
import { useState, useEffect } from "react";
import { useTranslation } from "../../../../helpers/useTranslation";
import { yupResolver } from "@hookform/resolvers/yup";
import { DocumentToolbar } from "../../../../components/DocumentToolbar/DocumentToolbar";
import { EnvironmentalImpact } from "./EnvironmentalImpact/EnvironmentalImpact";
import { Distribution } from "./Distribution/Distribution";
import { Demolition } from "./Demolition/Demolition";
import { CompanyInformation } from "./CompanyInformation/CompanyInformation";
import { ArticleInformation } from "./ArticleInformation/ArticleInformation";
import { ChemicalContent } from "./ChemicalContent/ChemicalContent";
import { RawMaterial } from "./RawMaterial/RawMaterial";
import { ConstructionPhase } from "./ConstructionPhase/ConstructionPhase";
import { UsagePhase } from "./UsagePhase/UsagePhase";
import { WasteManagement } from "./WasteManagement/WasteManagement";
import { IndoorEnvironment } from "./IndoorEnvironment/IndoorEnvironment";
import { DocumentVersion } from "./DocumentVersion/DocumentVersion";
import { CopyDocumentForm } from "./CopyDocument/CopyDocumentForm";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useUpdateEBVDDocument,
  usePublishEBVDDocument,
  useUnPublishEBVDDocument,
  useNewVersionEBVDDocument,
  useCopyEBVDDocument,
  useDeleteDocumentById,
  useGetDocumentPdfFromDocumentId,
  useGetDocumentXMLFromDocumentId,
} from "../../../../services/documentService";
import { useGetHPhrases } from "../../../../services/hPhraseService";
import { useGetProductContentInfoItem } from "../../../../services/productContentComponentService";
import { useParams } from "react-router";
import { useFormValidation } from "../../../../helpers/useFormValidation";
import ConfirmationErrorMessageModal from "../../../../components/ConfirmationModal/ConfirmationErrorMessageModal";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { ConfirmationDeleteModal } from "../../../../components/ConfirmationModal/ConfirmationDeleteModal";
import { usePrompt } from "../../../../helpers/usePrompts";
import { useRecentlyViewed } from "../../../../helpers/useRecentlyViewed";
import ErrorIcon from "@mui/icons-material/Error";
import { PublishDocumentModal } from "../../PublishDocumentModal/PublishDocumentModal";

type a11yProps = {
  id: string;
  "aria-controls": string;
};

type Params = {
  id: string;
};

type Props = {
  data?: EBVDDocument;
};

type SchemaType = "publish" | "save" | "initial";

export const DocumentSections = ({ data }: Props) => {
  const { get } = useTranslation();

  const { ebvdDocumentValidationSchema, updateEbvdDocumentValidationSchema } = useFormValidation();
  const [value, setTabValue] = useState(1);
  // const [isDisablePublish, setDisablePublish] = useState(false);
  const [errorMessageValue, setErrorMessageValue] = useState<any>([]);
  const { id } = useParams<Params>();
  const [schemaType, setSchemaType] = useState<SchemaType>("initial");
  const { mutateAsync: updateEBVDDocument } = useUpdateEBVDDocument();
  const { mutateAsync: copyDocument } = useCopyEBVDDocument();
  const { mutate: deleteDocument } = useDeleteDocumentById();
  const { mutate: documentPdf } = useGetDocumentPdfFromDocumentId();
  const { mutate: documentXML } = useGetDocumentXMLFromDocumentId();

  useRecentlyViewed(id, data?.Product.Name);

  const navigate = useNavigate();
  const hPhrasefilter = {
    TextFilter: " ",
  };

  const { data: hPhrasesData } = useGetHPhrases(hPhrasefilter);
  const { data: existingArticleData } = useGetProductContentInfoItem(data?.UserGroupId);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPublishDocumentModal, setShowPublishDocumentModal] = useState(false);

  const handleModalClose = () => {
    setShowErrorModal(false);
    setShowPublishDocumentModal(false);
  };

  const { mutateAsync: publishEBVDDocument } = usePublishEBVDDocument();
  const { mutateAsync: unPublishEBVDDocument } = useUnPublishEBVDDocument();
  const { mutateAsync: newVersionEBVDDocument } = useNewVersionEBVDDocument();
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleVersionHistory = (newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `section-tab-${index}`,
      "aria-controls": `section-tabpanel-${index}`,
    } as a11yProps;
  };

  const methods = useForm<EBVDDocument>({
    resolver: yupResolver(schemaType === "publish" ? ebvdDocumentValidationSchema : updateEbvdDocumentValidationSchema),
    defaultValues: data,
  });

  useEffect(() => {
    if (schemaType === "publish") {
      methods.handleSubmit(initiateDocumentPublish)();
      setSchemaType("initial");
      setShowErrorModal(true);
      setShowPublishDocumentModal(false);
    } else if (schemaType === "save") {
      methods.handleSubmit(updateDocument)();
      setSchemaType("initial");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaType, methods.handleSubmit]);

  const updateDocument = (eBVDDocument: EBVDDocument) => {
    if (eBVDDocument.ProductContent.CandidateListEdition === null)
      eBVDDocument.ProductContent.CandidateListEdition = "";
    updateEBVDDocument(eBVDDocument).then(() => {
      methods.reset(eBVDDocument);
      // setDisablePublish(false);
    });
  };

  const initiateDocumentPublish = (data: EBVDDocument) => {
    verifyPublishDocument(data);
  };

  const verifyPublishDocument = (data: EBVDDocument) => {
    if (Object.entries(methods.formState.errors).length > 0) {
      setShowErrorModal(true);
    } else if (data?.DocumentIsReadOnly === false) {
      setShowPublishDocumentModal(true);
    } else {
      publishDocument();
    }
  };

  const publishDocument = () => {
    publishEBVDDocument({
      DocumentId: data?.DocumentId,
      VersionNumber: data?.VersionNumber,
    } as DocumentVersion).then((res) => {
      methods.reset(data);
      methods.setValue("IsLatestPublished", res.IsLatestPublished);
    });
    setShowPublishDocumentModal(false);
  };

  const unPublishDocument = () => {
    unPublishEBVDDocument({
      DocumentId: data?.DocumentId,
      VersionNumber: data?.VersionNumber,
    } as DocumentVersion).then((res) => {
      methods.setValue("IsLatestPublished", res.IsLatestPublished);
    });
  };

  const savePDFDocument = () => {
    documentPdf({
      versionNumber: data?.VersionNumber,
      documentId: data?.DocumentId,
    });
  };

  const saveXMLDocument = () => {
    documentXML({
      versionNumber: data?.VersionNumber,
      documentId: data?.DocumentId,
    });
  };

  const copyMethods = useForm<CopyDocument>({});

  const [showCopy, setShowCopy] = useState(false);

  const handleCopyDocument = (copyData: CopyDocument, e: any) => {
    copyDocument(copyData).then((result: EBVDDocument) => {
      navigate(`/documents/${result.DocumentId}`);
      setTabValue(1);
    });
    setShowCopy(false);
    copyMethods.reset();
  };

  const handleCopyModalClose = () => {
    setShowCopy(false);
    copyMethods.reset();
  };

  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteDocument = () => {
    deleteDocument(id);
    navigate(`/documents`);
    setShowDelete(false);
  };

  const newVersionDocument = () => {
    newVersionEBVDDocument({ DocumentId: data?.DocumentId, DocumentValidFromDate: new Date() }).then(
      (result: EBVDDocument) => {
        navigate(`/documents/${result.DocumentId}`);
        setTabValue(1);
      },
    );
  };

  useEffect(() => {
    const errorMessages = Object.entries(methods.formState.errors).map((k) => k[1]);
    setErrorMessageValue(errorMessages);
  }, [methods.formState.errors]);

  usePrompt(get("unsavedDocumentPromptMessage"), methods.formState.isDirty);
  var articleInfo = true;
  var chemicalContent = true;
  var rawMaterial = true;
  var environmentalImpact = true;
  var distribution = true;
  var constructionPhase = true;
  var usagePhase = true;
  var wasteManagement = true;
  var demolition = true;
  var indoorEnvironment = true;

  if (
    methods.formState.errors.NoGTINComment === undefined &&
    methods.formState.errors.DocumentProductSystems === undefined &&
    methods.formState.errors.Product?.DeclarationOfPerformanceNumber === undefined &&
    methods.formState.errors.Product?.Description === undefined &&
    methods.formState.errors.DocumentProductGroupSystems === undefined &&
    methods.formState.errors.ChangeDescription === undefined
  ) {
    articleInfo = false;
  }
  if (
    methods.formState.errors.ProductContent === undefined &&
    methods.formState.errors.ProductContentComponents === undefined
  ) {
    chemicalContent = false;
  }
  if (
    methods.formState.errors.ProductRawMaterial === undefined &&
    methods.formState.errors.ProductRecycledMaterials === undefined
  ) {
    rawMaterial = false;
  }
  if (methods.formState.errors.ProductEnvironmentalImpact === undefined) {
    environmentalImpact = false;
  }
  if (methods.formState.errors.ProductDistribution === undefined) {
    distribution = false;
  }
  if (methods.formState.errors.ProductConstruction === undefined) {
    constructionPhase = false;
  }
  if (methods.formState.errors.ProductConstruction === undefined) {
    constructionPhase = false;
  }
  if (methods.formState.errors.ProductUsage === undefined) {
    usagePhase = false;
  }
  if (methods.formState.errors.ProductDisassembly === undefined) {
    demolition = false;
  }
  if (
    methods.formState.errors.DocumentWasteCodeMountedItems === undefined &&
    methods.formState.errors.DocumentWasteCodeDeliveredItems === undefined &&
    methods.formState.errors.ProductWasteManagement?.Reusable === undefined &&
    methods.formState.errors.ProductWasteManagement?.EnergyRecoveryComment === undefined &&
    methods.formState.errors.ProductWasteManagement?.CanRecycleComment === undefined &&
    methods.formState.errors.ProductWasteManagement?.RestrictionsAndRecommendationsComment === undefined
  ) {
    wasteManagement = false;
  }
  if (
    methods.formState.errors.ProductEmissions === undefined &&
    methods.formState.errors.ProductIndoorEnvironment?.CriticalMoistureCondition === undefined
  ) {
    indoorEnvironment = false;
  }
  const getIcon = (value: boolean) => {
    if (value === true)
      return (
        <ErrorIcon
          color="error"
          style={{
            display: "flex",
            alignItems: "end",
            flexDirection: "row",
            justifyContent: "center",
            height: 30,
            marginRight: 10,
          }}
        />
      );
    return "";
  };

  const tabs = [
    {
      labelKey: get("sectionCompanyInformation"),
      component: <CompanyInformation data={data?.UserGroup} />,
      errorValue: false,
    },
    {
      labelKey: get("sectionArticleInformation"),
      component: <ArticleInformation data={data} />,
      errorValue: articleInfo,
    },
    {
      labelKey: get("sectionChemicalContent"),
      component: <ChemicalContent data={data} hPhrasesData={hPhrasesData} existingArticleData={existingArticleData} />,
      errorValue: chemicalContent,
    },
    {
      labelKey: get("sectionRawMaterials"),
      component: <RawMaterial data={data} />,
      errorValue: rawMaterial,
    },
    {
      labelKey: get("sectionEnvironmentalImpact"),
      component: <EnvironmentalImpact data={data} />,
      errorValue: environmentalImpact,
    },
    {
      labelKey: get("sectionDistribution"),
      component: <Distribution data={data} />,
      errorValue: distribution,
    },
    {
      labelKey: get("sectionConstructionPhase"),
      component: <ConstructionPhase data={data} />,
      errorValue: constructionPhase,
    },
    {
      labelKey: get("sectionUsagePhase"),
      component: <UsagePhase data={data} />,
      errorValue: usagePhase,
    },
    {
      labelKey: get("sectionDemolition"),
      component: <Demolition data={data} />,
      errorValue: demolition,
    },
    {
      labelKey: get("sectionWasteManagement"),
      component: <WasteManagement data={data} />,
      errorValue: wasteManagement,
    },
    {
      labelKey: get("sectionIndoorEnvironment"),
      component: <IndoorEnvironment data={data} />,
      errorValue: indoorEnvironment,
    },
    {
      labelKey: "",
      // component: <DocumentVersion setTabValue={setTabValue} setDisablePublish={setDisablePublish} />,
      component: <DocumentVersion setTabValue={setTabValue} userGroupId={data?.UserGroupId} />,
      errorValue: false,
    },
  ];
  return (
    <>
      <FormProvider {...methods}>
        {errorMessageValue.length !== 0 && (
          <ConfirmationErrorMessageModal
            show={showErrorModal}
            handleClose={() => handleModalClose()}
            title={get("errorMessageTitle")}
            cancelButtonName={get("close")}
            errorMessageValue={get("errorMessageDescription")}
          />
        )}
        <ConfirmationDeleteModal
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleConfirmation={() => handleDeleteDocument()}
          title={get("deleteDocumentTitle")}
          saveButtonName={get("radioButtonYes")}
          cancelButtonName={get("radioButtonNo")}
        >
          {get("deleteDocumentMessage")}
        </ConfirmationDeleteModal>

        <PublishDocumentModal
          title={get("deleteDocumentTitle")}
          show={showPublishDocumentModal}
          handleClose={() => setShowPublishDocumentModal(false)}
          handleConfirmation={() => publishDocument()}
          saveButtonName={get("toolBarPublish")}
          cancelButtonName={get("cancelButtonText")}
          data={data}
        />

        <DocumentToolbar
          updateDocument={() => setSchemaType("save")}
          initiateDocumentPublish={() => setSchemaType("publish")}
          savePDFDocument={() => savePDFDocument()}
          versionHistory={() => handleVersionHistory(12)}
          createXML={() => saveXMLDocument()}
          unPublishDocument={unPublishDocument}
          verifyPublishDocument={verifyPublishDocument}
          newVersionDocument={newVersionDocument}
          isDirty={methods.formState.isDirty}
          CanEdit={data?.DocumentPermissions.CanEdit ?? false}
          CanPublish={data?.DocumentPermissions.CanPublish ?? false}
          CanCreateNewVersion={data?.DocumentPermissions.CanCreateNewVersion ?? false}
          CanDelete={data?.DocumentPermissions.CanDelete ?? false}
          CanUnPublish={data?.DocumentPermissions.CanUnPublish ?? false}
          isPublished={data?.DocumentStatusId === 1}
          isTemplate={data?.IsTemplate}
          documentStatusId={data?.DocumentStatusId}
          userGroupId={data?.UserGroupId}
          setShowCopy={setShowCopy}
          setShowDelete={setShowDelete}
        />

        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            visibleScrollbar
            aria-label="section-tabs"
            sx={{
              flexBasis: "30%",
              pl: 2,
              ".MuiTabs-indicator": {
                left: 0,
                top: "10px",
                width: "5px",
                marginTop: "9px",
                maxHeight: "30px",
                borderRadius: "2.5px",
              },
              ".MuiTabs-flexContainerVertical": {
                gap: 1,
                pr: 4,
              },
            }}
          >
            <Tab
              disabled
              sx={{
                alignItems: "start",
                typography: "overline",
                "&.Mui-disabled": {
                  color: "black",
                },
              }}
              label={get("sections")}
            />
            {tabs.slice(0, 11).map((tab, idx) => (
              <Tab
                sx={{
                  maxWidth: 1000,
                  minWidth: 1000,
                  minHeight: 48,
                  justifyContent: "start",
                  alignItems: "start",
                  flexDirection: "row",
                  typography: "h6",
                  color: tab.errorValue ? "red" : "black",
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: tab.errorValue ? "red" : "black",
                  },
                }}
                icon={getIcon(tab.errorValue)}
                label={`${idx + 1}. ${tab.labelKey}`}
                {...a11yProps(idx)}
                key={idx}
              />
            ))}
          </Tabs>

          {tabs.map((tab, idx) => (
            <DocumentTabPanel index={idx + 1} value={value} key={idx}>
              {tab.component}
            </DocumentTabPanel>
          ))}
        </Box>
      </FormProvider>
      <FormProvider {...copyMethods}>
        <ConfirmationModal
          show={showCopy}
          handleClose={() => handleCopyModalClose()}
          handleConfirmation={() => copyMethods.handleSubmit(handleCopyDocument)()}
          title={get("copyDocumentButton")}
          saveButtonName={get("copyDocumentButton")}
          cancelButtonName={get("documentCancel")}
        >
          <CopyDocumentForm documentData={data} />
        </ConfirmationModal>
      </FormProvider>
    </>
  );
};
