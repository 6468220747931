import { Typography, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../../../helpers/useTranslation";
import { Box } from "@mui/system";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useState } from "react";
import { CreateDocumentModal } from "../../Documents/CreateDocumentModal/CreateDocumentModal";
import { AdminAuthenticationTemplate } from "../../../components/AdminAuthenticationTemplate/AdminAuthenticationTemplate";
import { AdminButton } from "./AdminButton/AdminButton";
import { useGetUser } from "../../../services/userService";
import { ImportAuthenticationTemplate } from "../../../components/ImportAuthenticationTemplate/ImportAuthenticationTemplate";
import { ImportButton } from "./ImportButton/ImportButton";

export const WelcomeSection = () => {
  const { get } = useTranslation();
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const { data } = useGetUser();

  return (
    <>
      <CreateDocumentModal open={showAdd} setOpen={setShowAdd} />
      <Grid container sx={{ p: 3, padding: "30px", paddingBottom: "50px", paddingTop: "34px", paddingLeft: "50px" }}>
        <Grid item md={8}>
          <Typography variant="h5" color="black">
            {get("welcomeSectionTitle")}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 3 }}>
            {get("welcomeSectionContent")}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 2, fontStyle: "italic" }}>
            <Box
              sx={{
                display: "flex",
                color: "#44a4b7",
                alignItems: "center",
              }}
            >
              <a href="https://www.ivl.se/om-oss/dotterbolag/ebvd.html" target="_blank" rel="noreferrer">
                <Typography variant="subtitle1" color="#44a4b7" sx={{ textDecoration: "none" }}>
                  {get("welcomeSectionContentToHomepage")}
                </Typography>
              </a>
              <OpenInNewIcon
                sx={{
                  ml: 2,
                  mr: 4,
                }}
              />
            </Box>
          </Typography>
          <Box>
            <Typography
              variant="subtitle1"
              color="#44a4b7"
              sx={{ textDecoration: "none", fontStyle: "italic" }}
              component={Link}
              to={`/about`}
            >
              {get("welcomeSectionContentAbouteBVD")}
            </Typography>
          </Box>
        </Grid>
        <Grid item container justifyContent="flex-end" md={4} height="100%">
          <AdminAuthenticationTemplate>
            <AdminButton />
          </AdminAuthenticationTemplate>
          <AuthenticatedTemplate>
            <Button
              onClick={() => navigate("/documents")}
              variant="contained"
              sx={{
                ml: 1,
                mt: 1,
              }}
            >
              {get("manageeBVD")}
            </Button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              onClick={() => navigate("/documents")}
              variant="contained"
              sx={{
                ml: 1,
                mt: 1,
              }}
            >
              {get("searcheBVD")}
            </Button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {data && data?.UserGroups.filter((x) => x.CanCreateNew).length > 0 && (
              <Button
                onClick={() => setShowAdd(true)}
                startIcon={<AddIcon />}
                variant="contained"
                sx={{
                  ml: 1,
                  mt: 1,
                }}
              >
                {get("frontPageNewDocumentButton")}
              </Button>
            )}
          </AuthenticatedTemplate>
          {/* Only shows button if import is not invalid for the user */}
          {data && !data.InvalidApplicationFeatureIds.includes(1) && (
            <ImportAuthenticationTemplate>
              <ImportButton />
            </ImportAuthenticationTemplate>
          )}
        </Grid>
      </Grid>
    </>
  );
};
