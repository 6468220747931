import { Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "../../../helpers/useTranslation";

export const ContactSection = () => {
  const { get } = useTranslation();

  return (
    <Grid container sx={{ p: 3, padding: "30px", paddingBottom: "100px", paddingTop: "64px", paddingLeft: "50px" }}>
      <Grid item md={4}>
        <Grid item md={12}>
          <Typography
            variant="h5"
            color="#44a4b7"
            sx={{ textDecoration: "none" }}
            component={Link}
            to={`/`}
            display="none"
          >
            {get("contactSectionLink1")}
            {"  "}
            <OpenInNewIcon sx={{ fontSize: "20px" }} />
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography
            variant="h5"
            color="#44a4b7"
            sx={{ textDecoration: "none" }}
            component={Link}
            to={`/`}
            display="none"
          >
            {get("contactSectionLink2")}
            {"  "}
            <OpenInNewIcon sx={{ fontSize: "20px" }} />
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography
            variant="h5"
            color="#44a4b7"
            sx={{ textDecoration: "none" }}
            component={Link}
            to={`/`}
            display="none"
          >
            {get("contactSectionLink3")}
            {"  "}
            <OpenInNewIcon sx={{ fontSize: "20px" }} />
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={4}>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{get("contactSectionAddressTitle")}</Typography>
        <Typography variant="body1">{get("contactSectionAddress1")}</Typography>
        <Typography variant="body1">{get("contactSectionAddress2")}</Typography>
      </Grid>
    </Grid>
  );
};
