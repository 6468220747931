export const queryEnums = {
  documents: "getAllDocuments",
  document_detail: "getDocumentById",
  document_Version_detail: "getDocumentVersionById",
  document_delete: "deleteDocumentId",
  productGroups: "getAllProductGroup",
  transactionItems: "getTransactionItems",
  wasteCodes: "getAllWasteCodes",
  countries: "getAllCountries",
  comparisonOperators: "getAllComparisonOperators",
  turnovers: "getAllTurnOvers",
  evbdsCount: "getAllGetEstimatedDocumentCounts",
  userService: "getUser",
  productContentComponent: "getProductContents",
  updateProductContentComponent: "updateProductContentComponent",
  createProductContentComponent: "createProductContentComponent",
  deleteProductContentComponent: "deleteProductContentComponent",
  copyProductContentComponents: "copyProductContentComponents",
  renameProductContentComponents: "renameProductContentComponents",
  usergroupService: "getUserGroup",
  hPhrases: "getAllHPhrases",
  userGroupUser: "getUserGroupUser",
  userGroup: "getUserGroup",
  userGroupData: "GetDataItems",
  digitalTransfer: "getDigitalTransfer",
};
