import React, { ReactNode } from "react";
import { DocumentBreadcrumb } from "../components/CustomBreadcrumbs/DocumentBreadcrumb/DocumentBreadcrumb";
import { TranslatedBreadcrumb } from "../components/CustomBreadcrumbs/TranslatedBreadcrumb/TranslatedBreadcrumb";
import { DocumentDetailPage } from "../pages/Documents/DocumentDetailPage/DocumentDetailPage";
import { OrganizationDetail } from "../pages/Admin/OrganizationDetail/OrganizationDetail";
import { DocumentsPage } from "../pages/Documents/DocumentsPage";
import { IndexPage } from "../pages/Index/IndexPage";
import { TranslatedKey } from "../translation/translatedKey";
import { OrganizationList } from "../pages/Admin/OrganizationList/OrganizationList";
import { RegistrationPage } from "../pages/Registration/RegistrationPage";
import { CreateAccount } from "../pages/Admin/CreateAccount/CreateAccount";
import { RegistrationSuccess } from "../pages/Registration/RegistrationSuccess";
import { RegistrationConfirmation } from "../pages/Registration/RegistrationConfirmation";
import { AboutPage } from "../pages/About/AboutPage";
import { ImportPage } from "../pages/Import/ImportPage/ImportPage";
import { OrganizationListForImport } from "../pages/Import/OrganizationList/OrganizationListForImport";
import { TransactionPage } from "../pages/Documents/InternalSearch/ImportPage/TransactionPage";
import { OrganizationListForTransaction } from "../pages/Documents/InternalSearch/ImportPage/OrganizationList/OrganizationListForTransaction";

interface Map {
  [key: string]: Route;
}

export interface Route {
  path: string;
  component: React.FC;
  exact: boolean;
  protected: boolean;
  nameKey: TranslatedKey;
  breadcrumb?: ReactNode;
}

export const routes: Map = {
  index: {
    path: `/`,
    component: IndexPage,
    exact: true,
    protected: false,
    nameKey: "routeHome",
    breadcrumb: TranslatedBreadcrumb,
  },
  documents: {
    path: `/documents`,
    component: DocumentsPage,
    exact: true,
    protected: false,
    nameKey: "routeDocuments",
    breadcrumb: TranslatedBreadcrumb,
  },
  documents_detail: {
    path: `/documents/:id`,
    component: DocumentDetailPage,
    exact: true,
    protected: true,
    nameKey: "routeDocuments",
    breadcrumb: DocumentBreadcrumb,
  },
  documents_transaction: {
    path: `/transactions`,
    component: OrganizationListForTransaction,
    exact: true,
    protected: true,
    nameKey: "routeTransactions",
    breadcrumb: TranslatedBreadcrumb,
  },
  documents_transaction_page: {
    path: `/transactions/:id`,
    component: TransactionPage,
    exact: true,
    protected: true,
    nameKey: "routeTransactions",
    breadcrumb: null,
  },
  admin: {
    path: `/admin`,
    component: OrganizationList,
    exact: true,
    protected: true,
    nameKey: "routeAdmin",
    breadcrumb: TranslatedBreadcrumb,
  },
  import: {
    path: `/import`,
    component: OrganizationListForImport,
    exact: true,
    protected: true,
    nameKey: "routeImport",
    breadcrumb: TranslatedBreadcrumb,
  },
  import_page: {
    path: `/import/:id`,
    component: ImportPage,
    exact: true,
    protected: true,
    nameKey: "routeImport",
    breadcrumb: null,
  },
  CreateAccount: {
    path: `/CreateAccount`,
    component: CreateAccount,
    exact: true,
    protected: false,
    nameKey: "routeCreateAccount",
    breadcrumb: TranslatedBreadcrumb,
  },
  organization_detail: {
    path: `/admin/:id`,
    component: OrganizationDetail,
    exact: true,
    protected: true,
    nameKey: "routeAdmin",
    breadcrumb: null,
  },
  registration1: {
    path: `/registration`,
    component: RegistrationPage,
    exact: true,
    protected: false,
    nameKey: "routeRegistration",
    breadcrumb: TranslatedBreadcrumb,
  },
  registration: {
    path: `/registration?securitystamp=:securityStamp`,
    component: RegistrationPage,
    exact: true,
    protected: false,
    nameKey: "routeRegistration",
    breadcrumb: TranslatedBreadcrumb,
  },
  registrationConfirmation: {
    path: `/registrationConfirmation`,
    component: RegistrationConfirmation,
    exact: true,
    protected: false,
    nameKey: "routeRegistration",
    breadcrumb: TranslatedBreadcrumb,
  },
  registrationSuccess: {
    path: `/registrationRequestSent`,
    component: RegistrationSuccess,
    exact: true,
    protected: false,
    nameKey: "routeRegistrationSuccess",
    breadcrumb: TranslatedBreadcrumb,
  },
  about: {
    path: `/about`,
    component: AboutPage,
    exact: true,
    protected: false,
    nameKey: "routeAbout",
    breadcrumb: TranslatedBreadcrumb,
  },
};

export const routesArray = Object.values(routes);
