import { Chip, Grid, TableCell, Typography, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useGetDocumentPdfFromDocumentId } from "../../../services/documentService";
import { useTranslation } from "../../../helpers/useTranslation";
import { useDateFormat } from "../../../helpers/useDateFormat";
import { useDocumentStatus } from "../../../helpers/useDocumentStatus";

type Props = {
  item: EBVDListDocument;
  documentIds: number[];
  setDocumentIds: any;
  documentIndex: number;
};
const InternalSearchItemLists = ({ item, documentIds, setDocumentIds, documentIndex }: Props) => {
  const { get } = useTranslation();
  const { format } = useDateFormat();
  const { documentStatus } = useDocumentStatus();
  const { mutate: documentPdf } = useGetDocumentPdfFromDocumentId();
  const handleChange = (event: any) => {
    if (event.target.checked) {
      setDocumentIds([...documentIds, event.target.value]);
    } else {
      setDocumentIds(documentIds.filter((x) => x !== event.target.value));
    }
  };

  const DocumentUpdatedTimeOneHourPlus = () => {
    const DocumentUpdatedTimeOneHourPlus = new Date(item.DocumentUpdatedTime);
    DocumentUpdatedTimeOneHourPlus.setHours(DocumentUpdatedTimeOneHourPlus.getHours() + 1);

    return DocumentUpdatedTimeOneHourPlus;
  };

  return (
    <>
      <TableCell sx={{ padding: "16px 0px 16px 2px" }}>
        <Grid container sx={{ mt: 2, mb: 2, paddingBottom: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <AuthenticatedTemplate>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    value={documentIndex}
                    onChange={handleChange}
                    checked={documentIds.some((x) => x.toString() === documentIndex.toString())}
                  />
                }
                label={get("searchDocumentId") + " " + item.DocumentId}
              />
              <Chip
                size="small"
                sx={{ ml: 1, borderRadius: "5px!important", p: 0, color: "white", backgroundColor: "#008387" }}
                label={documentStatus(item.DocumentStatusId)}
              />
            </AuthenticatedTemplate>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <AuthenticatedTemplate>
              <Typography
                variant="h5"
                color="black"
                sx={{ textDecoration: "none" }}
                component={Link}
                to={`/documents/${item.InternalDocumentId}`}
              >
                {item.ProductName}
              </Typography>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Tooltip title={get("toolTipDownloadPDF")} placement="bottom-start">
                <Typography
                  variant="h5"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    documentPdf({
                      versionNumber: item.DocumentVersion,
                      documentId: item.InternalDocumentId,
                    })
                  }
                >
                  {item.ProductName}
                </Typography>
              </Tooltip>
            </UnauthenticatedTemplate>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {get("searchDocumentSupplier")}
              {item.CompanyName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {get("searchDocumentUpdated")}
              {format(DocumentUpdatedTimeOneHourPlus())}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "inline-flex" }}>
            <Typography variant="body1">
              {get("searchDocumentVersion")}
              {item.DocumentVersion}
            </Typography>
            {item.IsLatestVersion === true ? (
              <Chip
                size="small"
                sx={{ ml: 5, borderRadius: "5px!important", p: 0, color: "white", backgroundColor: "#008387" }}
                label={get("latestVersiontext")}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
};

export default InternalSearchItemLists;
