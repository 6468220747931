import { useQuery, useMutation } from "react-query";
import { api } from "../config/apiEndpoints";
import { queryClient } from "../config/queryClient";
import { queryEnums } from "../config/queryEnums";
import { useMessage } from "../helpers/useMessages";
import { useRequest } from "../helpers/useRequest";
import { useTranslation } from "../helpers/useTranslation";
import fileDownload from "js-file-download";

const url = api.document;

// TODO: Change whenever API supports better handling
export const useGetDocuments = () => {
  const { post } = useRequest();

  return useQuery<EBVDListResults>(queryEnums.documents, () => post(`${url}/GetDocuments`, null), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetDocumentsInternalSearch = (filter: InternalSearchFilter) => {
  const { post } = useRequest();

  return useQuery<EBVDListResults>([queryEnums.documents, filter], () => post(`${url}/GetDocuments`, filter), {
    suspense: false,
  });
};

export const useGetDocumentsHeaderSearch = (filter: InternalSearchFilter | undefined) => {
  const { post } = useRequest();

  return useQuery<EBVDListResults>([queryEnums.documents, filter], () => post(`${url}/GetDocuments`, filter), {
    suspense: false,
    enabled: !!filter?.TextFilter,
    refetchOnWindowFocus: false,
  });
};

export const useGetDocumentByDocumentId = (id: string | undefined) => {
  const { post } = useRequest();

  return useQuery<EBVDDocument>(
    [queryEnums.document_detail, id],
    () =>
      post(`${url}/GetDocumentFromDocumentId`, {
        DocumentId: id,
        LoadDocumentChildren: true,
        LoadUserGroup: true,
      }),
    {
      enabled: Boolean(id),
    },
  );
};

export const useCreateEBVDDocument = () => {
  const { post } = useRequest();

  return useMutation((createDocument: CreateDocument) => post(`${url}/CreateDocument`, createDocument), {});
};

export const useUpdateEBVDDocument = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation(
    (eBVDDocument: EBVDDocument) => {
      const data = {
        ...eBVDDocument,
        UpdateDocumentAttachments: true,
        UpdateDocumentProductGroupSystems: true,
        UpdateDocumentProductSystems: true,
        UpdateDocumentReferences: true,
        UpdateDocumentUsers: true,
        UpdateDocumentWasteCodeDeliveredItems: true,
        UpdateDocumentWasteCodeMountedItems: true,
        UpdateProduct: true,
        UpdateProductConstruction: true,
        UpdateProductContent: true,
        UpdateProductContentComponents: false,
        UpdateProductDisassembly: true,
        UpdateProductDistribution: true,
        UpdateProductEmissions: true,
        UpdateProductEnvironmentalImpact: true,
        UpdateProductIndoorEnvironment: true,
        UpdateProductRawMaterial: true,
        UpdateProductRawMaterialComponents: true,
        UpdateProductRecycledMaterials: true,
        UpdateProductUsage: true,
        UpdateProductWasteManagement: true,
      } as EBVDUpdateDocument;

      return post(`${url}/UpdateDocument`, data);
    },
    {
      onSuccess: (eBVDUpdateResult: EBVDUpdateResult) => {
        queryClient.invalidateQueries(queryEnums.document_detail);
        send(`eBVD: ${eBVDUpdateResult.ProductName} ${get("toastSaved")}.`);
      },
      onError: (eBVDUpdateResult: any) => {
        send(`Error on updating:  ${eBVDUpdateResult}`);
      },
    },
  );
};

export const usePublishEBVDDocument = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation(
    (data: DocumentVersion) => {
      return post(`${url}/PublishDocument`, data);
    },
    {
      onSuccess: (eBVDPublishResult: EBVDUpdateResult) => {
        queryClient.invalidateQueries(queryEnums.document_detail);
        send(`eBVD: ${eBVDPublishResult.ProductName} ${get("toastPublish")}.`);
      },
      onError: (eBVDPublishResult: any) => {
        send(`Error on Publish:  ${eBVDPublishResult}`);
      },
    },
  );
};

export const useUnPublishEBVDDocument = () => {
  const { post } = useRequest();
  const { send } = useMessage();
  const { get } = useTranslation();

  return useMutation(
    (data: DocumentVersion) => {
      return post(`${url}/UnpublishDocument`, data);
    },
    {
      onSuccess: (eBVDPublishResult: EBVDUpdateResult) => {
        queryClient.invalidateQueries(queryEnums.document_detail);
        send(`eBVD: ${eBVDPublishResult.ProductName} ${get("toastUnPublish")}.`);
      },
      onError: (eBVDPublishResult: any) => {
        send(`Error on UnPublish:  ${eBVDPublishResult}`);
      },
    },
  );
};

export const usePublishEBVDDocuments = () => {
  const { post } = useRequest();
  const { send } = useMessage();

  return useMutation(
    (data: DocumentIdsList) => {
      return post(`${url}/PublishDocuments`, data);
    },
    {
      // onSuccess: (eBVDPublishResult: EBVDUpdateResult) => {
      //   queryClient.invalidateQueries(queryEnums.document_detail);
      //   send(`eBVD: ${eBVDPublishResult.ProductName} ${get("toastPublish")}.`);
      // },
      onError: (eBVDPublishResult: any) => {
        send(`Error on Publish:  ${eBVDPublishResult}`);
      },
    },
  );
};

export const useUnpublishEBVDDocuments = () => {
  const { post } = useRequest();
  const { send } = useMessage();

  return useMutation(
    (data: DocumentIdsList) => {
      return post(`${url}/UnpublishDocuments`, data);
    },
    {
      // onSuccess: (eBVDPublishResult: EBVDUpdateResult) => {
      //   queryClient.invalidateQueries(queryEnums.document_detail);
      //   send(`eBVD: ${eBVDPublishResult.ProductName} ${get("toastUnPublish")}.`);
      // },
      onError: (eBVDPublishResult: any) => {
        send(`Error on UnPublish:  ${eBVDPublishResult}`);
      },
    },
  );
};

export const useGetDocumentPdfFromDocumentId = () => {
  const { get } = useRequest();
  return useMutation(
    (filter: any) => {
      return get(`${url}/GetDocumentPdfFromDocumentId/${filter.documentId}/${filter.versionNumber}`, null, "blob");
    },
    {
      onSuccess: (result: File, filter) => {
        fileDownload(result, `Download_${filter.documentId}.pdf`);
      },
    },
  );
};

export const useGetDocumentXMLFromDocumentId = () => {
  const { get } = useRequest();
  return useMutation(
    (filter: any) => {
      return get(`${url}/GetDocumentXMLFromDocumentId/${filter.documentId}/${filter.versionNumber}`, null, "blob");
    },
    {
      onSuccess: (result: File, filter) => {
        fileDownload(result, `Download_${filter.documentId}.xml`);
      },
    },
  );
};

export const useGetDocumentExcelTemplate = () => {
  const { get } = useRequest();
  const { send } = useMessage();

  return useMutation(
    () => {
      return get(`${url}/GetDocumentExcelTemplate/`, null, "blob");
    },
    {
      onSuccess: (result: File) => {
        fileDownload(result, `eBVDimportfiletemplate.xlsx`);
      },
      onError: (result: any) => {
        send(`Error on Exporting:  ${result}`);
      },
    },
  );
};

export const useGetDocumentExcelFromDocumentIds = () => {
  const { post } = useRequest();
  const { send } = useMessage();

  return useMutation((exportDocuments: ExportDocuments) => post(`${url}/ExportDocuments`, exportDocuments, "blob"), {
    onSuccess: (result: File) => {
      fileDownload(result, `eBVDexportfile.xlsx`);
    },
    onError: (result: any) => {
      send(`Error on Exporting:  ${result}`);
    },
  });
};

export const useImportEBVDDocument = () => {
  const { post } = useRequest();

  return useMutation((importDocument: ImportDocument) => post(`${url}/ImportDocuments`, importDocument), {});
};

export const useGetDocumentVersionsFromDocumentId = (id: string | undefined) => {
  const { post } = useRequest();

  return useQuery<EBVDVersionDocumentResult[]>(
    [queryEnums.document_Version_detail, id],
    () =>
      post(`${url}/GetDocumentVersionsFromDocumentId`, {
        DocumentId: id,
        PermissionFilter: 0,
        SortType: 0,
        SortAscending: true,
      }),
    {
      enabled: Boolean(id),
    },
  );
};

export const useCopyEBVDDocument = () => {
  const { post } = useRequest();
  return useMutation((copyDocument: CopyDocument) => post(`${url}/CopyDocument`, copyDocument), {});
};

export const useDeleteDocumentById = () => {
  const { get } = useRequest();
  return useMutation((id?: string) => get(`${url}/DeleteDocument/${id}`, null), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryEnums.documents);
    },
  });
};

export const useNewVersionEBVDDocument = () => {
  const { post } = useRequest();

  return useMutation(
    (newDocumentVersion: NewDocumentVersion) => post(`${url}/NewDocumentVersion`, newDocumentVersion),
    {},
  );
};
